
.container {
  width: 50%;
  transition: all 0.5s;
}
.stats {
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 70%;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
}

.title.has-text-danger {
  color: #f14668 !important;
}
.correct-answer {
  margin-bottom: 20px;
}
textarea.answer {
  background: none !important;
  &:focus {
    border: 0 !important;
  }
}

#example-sentence {
  position: absolute;
  top: 10px;
  right: 10px;
}

.question-word {
  text-emphasis-style: dot;
  text-emphasis-position: under left;
  &:hover {
    color: #fc7234;
    text-emphasis-color: #fc7234;
    cursor: pointer;
  }
}

.press-enter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.result-notification {
  min-height: 100px;
  padding: 10px 30px;
  margin-bottom: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 600;
  font-size: 130%;
  &.correct {
    background: #dff9dd;
    border-top: 3px solid #9bc53d;
    color: #9bc53d;
  }
  &.wrong {
    background: #f9dddd;
    border-top: 3px solid #f14668;
    color: #f14668;
  }
  .level {
    width: 100%;
  }
}

